<template>
  <div class="training-list-table">
    <v-text-field
      v-if="searchBar"
      :label="$t('globals.search')"
      class="actions__search pb-4"
      dense
      hide-details
      outlined
      prepend-inner-icon="mdi-magnify"
      @input="filterObject"
    />
    <div class="training-list-table__actions">
      <v-btn class="actions__create" color="primary" depressed large @click="addUser()">
        {{ $t('admin.training.trainingList.table.button.addUser') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </div>

    <base-list
      :backgroundColor="backgroundColor"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :headerProps="{ sortIcon: null }"
      :headers="headers"
      :items="linkUser"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
      class="mt-4"
      className="img-first"
    >
      <template v-slot:[`item.coverImage`]="{ item }">
        <div class="container-img" @click="onItemClicked(item)">
          <img :src="item.profile.avatar" class="container-img__img" />
        </div>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.profile.firstName + ' ' + item.profile.lastName }}
      </template>

      <template v-slot:[`item.progress`]="{ item }">
        {{ item.totalLessonCompleted + '/' + totalLessonOfAllSection }}
      </template>

      <template v-slot:[`item.numUser`]="{ item }">
        {{ item.finalNote }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-space-around">
          <v-btn icon @click="openDialog('note', item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="openDialog('report', item)">
            <v-icon>mdi-clipboard-text</v-icon>
          </v-btn>
          <v-btn icon @click="openChatUser(item)">
            <v-icon>mdi-comment-text-outline</v-icon>
          </v-btn>
          <v-btn v-if="noCertificate(item)" icon @click="openGenerateCertsDialog(item)">
            <v-icon :title="$t('globals.generateCerts')">mdi-email-seal-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </base-list>

    <v-dialog
      v-if="dialog.report"
      v-model="dialog.report"
      width="500"
      @click:outside="closeDialog('report')"
    >
      <DialogReport
        :current-item="currentItem"
        :total-lesson="totalLessonOfAllSection"
        @close-dialog="closeDialog('report')"
      ></DialogReport>
    </v-dialog>
    <v-dialog
      v-if="dialog.edit"
      v-model="dialog.edit"
      width="500"
      @click:outside="closeDialog('edit')"
    >
      <DialogAdd
        :current-item="currentItem"
        :eventUsersId="eventUsersId"
        @close-dialog="closeDialog('edit')"
      ></DialogAdd>
    </v-dialog>
    <v-dialog
      v-if="dialog.note"
      v-model="dialog.note"
      width="800"
      @click:outside="closeDialog('note')"
    >
      <DialogNote :current-item="currentItem" @close-dialog="closeDialog('note')"></DialogNote>
    </v-dialog>

    <dialog-confirmation
      :title="$t('admin.training.certification.generateCertificates.title')"
      :visible="dialog.generateCerts"
      :content="$t('admin.training.certification.generateCertificates.content')"
      @cancel="closeDialog('generateCerts')"
      @confirm="generateCerts"
    />
  </div>
</template>

<script>
import i18n from '@/i18n';

import BaseList from '@/components/base-list/BaseList.vue';
import {
  GET_ALL_TRAININGS_SECTION_ADMIN,
  GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON,
  TRAINING_SECTION_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';
import DialogAdd from '@/views/admin/trainings/training-list/training-formation-seo/list-participants/list-participants-table/dialog/DialogAdd.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  APP_CHATS_MODULE,
  CLOSE_CHAT,
  DISPLAY_CHATS,
  GET_CHATS,
  HIDE_CHATS,
  OPEN_CHAT,
  UPDATE_SEARCH,
} from '@/stores/umanize-app/actions/chats/chats.actions';
import { FETCH_EVENT_USERS } from '@/stores/agnostic/actions/event/agnostic-event.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  GET_USERS_LINKED_TO_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import {
  GENERATE_USER_CERTIFICATE,
  TRAINING_LESSON_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import DialogReport from './dialog/DialogReport.vue';
import DialogNote from './dialog/DialogNote.vue';

export default {
  name: 'ListParticipantsTable',
  components: {
    DialogAdd,
    DialogReport,
    DialogNote,
    BaseList,
    DialogConfirmation,
  },
  props: ['user', 'searchBar', 'canEdit', 'canAdd', 'canDelete', 'backgroundColor'],
  data: () => ({
    currentItem: null,
    search: '',
    inputDialogSection: '',
    modalTitle: 'edit',
    totalLessonOfAllSection: 0,
    isEditing: true,
    dialog: {
      edit: false,
      delete: false,
      report: false,
      note: false,
      generateCerts: false,
    },
    eventUsersId: [],
    linkUser: [],
    headers: [
      {
        value: 'coverImage',
        width: 120,
      },
      {
        text: i18n.t('admin.training.trainingList.table.headers.name'),
        value: 'name',
      },
      {
        text: i18n.t('admin.training.trainingList.table.headers.progress'),
        value: 'progress',
        align: 'center',
      },
      {
        text: i18n.t('admin.training.trainingList.table.headers.finalNote'),
        value: 'finalNote',
        align: 'center',
        width: '100px',
      },
      {
        value: 'actions',
        width: '150px',
      },
    ],
    trainingItems: [
      {
        trainingId: '01234567-89ab-cdef-0123-456789abcdef',
        userId: '01234567-89ab-cdef-0123-456789abcdef',
        active: true,
        profile: {
          id: '01234567-89ab-cdef-0123-456789abcdef',
          firstName: 'string',
          lastName: 'string',
          avatar: 'http://example.com',
          about: 'string',
          smartMatchingScore: null,
        },
      },
    ],
  }),
  watch: {
    getUsersLinkedToTraining(newValue) {
      this.linkUser = newValue;
      this.eventUsersId = this.eventUsers.filter(
        (e) => !newValue.find((ele) => ele?.userId === e?.id),
      );
    },
  },
  computed: {
    eventId() {
      return this.$route.params.eventId;
    },
    partnerId() {
      return this.currentTraining?.id;
    },
    ...mapGetters(ADMIN_EVENT_MODULE, ['eventUsers']),
    ...mapGetters(TRAINING_ADMIN_MODULE, ['getUsersLinkedToTraining']),
    ...mapGetters(TRAINING_SECTION_ADMIN_MODULE, ['allPublicTrainingSectionData']),
  },
  methods: {
    ...mapActions(ADMIN_EVENT_MODULE, [FETCH_EVENT_USERS]),
    ...mapActions(TRAINING_ADMIN_MODULE, [GET_USERS_LINKED_TO_TRAINING]),
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [GENERATE_USER_CERTIFICATE]),
    onItemClicked(item) {
      this.$router.push({
        name: 'TrainingFormationSEO',
        params: {
          eventId: this.eventId,
          idTraining: item.id,
        },
      });
    },
    ...mapActions(APP_CHATS_MODULE, [
      GET_CHATS,
      OPEN_CHAT,
      CLOSE_CHAT,
      HIDE_CHATS,
      DISPLAY_CHATS,
      UPDATE_SEARCH,
    ]),
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [
      GET_ALL_TRAININGS_SECTION_ADMIN,
      GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON,
    ]),

    openChatUser(chatUser) {
      if (!this.displayed) {
        this[DISPLAY_CHATS]();
        this[OPEN_CHAT]({ userIds: chatUser.userId, id: chatUser.userId });
      }
    },
    filterObject(searchText) {
      if (searchText?.length) {
        this.linkUser = this.getUsersLinkedToTraining.filter((e) => {
          const searchValues = `${e.profile.firstName.toLowerCase()} ${e.profile.lastName.toLowerCase()}`;
          return searchValues.includes(searchText.toLowerCase().trim());
        });
      } else this.linkUser = JSON.parse(JSON.stringify(this.getUsersLinkedToTraining));
    },
    addUser() {
      this.modalTitle = 'create';
      this.dialog.edit = true;
    },
    openDialog(dialog, item) {
      this.dialog[dialog] = true;
      this.currentItem = item;
    },
    openGenerateCertsDialog(item) {
      this.currentTraining = { ...item };
      this.dialog.generateCerts = true;
    },
    noCertificate(item) {
      const cert = { ...item.certification };
      return !cert.created;
    },
    closeDialog(item) {
      this.currentTraining = null;
      this.dialog[item] = false;
    },
    openDeletePartnerDialog(item) {
      this.currentTraining = { ...item };
      this.dialog.delete = true;
    },
    async getUsers() {
      const { eventId, idTraining } = this.$route.params;
      await this[FETCH_EVENT_USERS]({ id: eventId });
      await this[GET_USERS_LINKED_TO_TRAINING]({ eventId, idTraining });
    },
    async generateCerts() {
      await this[GENERATE_USER_CERTIFICATE]({
        eventId: this.$route.params.eventId,
        trainingId: this.currentTraining.trainingId,
        userId: this.currentTraining.userId,
      });
      this.closeDialog('generateCerts');
    },
  },
  async mounted() {
    await this.getUsers();
    await this[GET_ALL_TRAININGS_SECTION_ADMIN]({
      eventId: this.$route.params.eventId,
      trainingId: this.$route.params.idTraining,
    });
    await this[GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON]();
    this.totalLessonOfAllSection = this.allPublicTrainingSectionData.reduce(
      (acc, curr) => acc + curr.lessons.length,
      0,
    );
    this.linkUser = [];
    this.getUsersLinkedToTraining.forEach((e) => {
      this.linkUser.push({ ...e });
    });
    this.eventUsersId = this.eventUsers.filter(
      (e) => !this.getUsersLinkedToTraining?.find((ele) => ele?.userId === e?.id),
    );
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/core/variables';
@import '~@/styles/core/mixins';

@include admin-layout;

.training-list-table {
  .clickable {
    cursor: pointer;
  }

  .container-img {
    display: flex;

    &__img {
      width: 150px;
      height: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  @include breakpoint(medium) {
    .container-img {
      width: 100px;
      height: 57px;
      border-radius: 10px 0 0 10px;
      border-right: 1px solid var(--v-gray-base);

      &__img {
        border-radius: 10px 0 0 10px;
      }
    }
  }
}
</style>
