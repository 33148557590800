<template>
  <v-container id="partners">
    <div>
      <v-breadcrumbs
        :style="{ opacity: trainingName ? 1 : 0 }"
        :items="breadcrumbs"
        large
        divider="<"
        class="ml-0 pl-0"
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <h2 class="mb-2">{{ $t('admin.training.trainingList.title.listOfParticipants') }}</h2>
    <ListParticipantsTable backgroundColor="var(--v-adminBackground-base)" :search-bar="true" />
  </v-container>
</template>

<script>
import ListParticipantsTable from '@/views/admin/trainings/training-list/training-formation-seo/list-participants/list-participants-table/ListParticipantsTable.vue';
import i18n from '@/i18n';
import {
  GET_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ListofParticipant',
  components: {
    ListParticipantsTable,
  },
  data: () => ({
    trainingName: false,
    canAdd: true,
    breadcrumbs: [
      {
        text: i18n.t('admin.training.trainingList.title.listOfTraining'),
        disabled: false,
        href: '../../',
      },
      {
        text: i18n.t('admin.training.trainingList.title.formationSeo'),
        disabled: false,
        href: './',
      },
      {
        text: 'List Of Participants',
        disabled: true,
      },
    ],
  }),
  computed: {
    ...mapGetters(TRAINING_ADMIN_MODULE, ['trainingData']),
  },
  methods: {
    ...mapActions(TRAINING_ADMIN_MODULE, [GET_TRAINING]),
  },
  async mounted() {
    const { idTraining, eventId } = this.$route.params;
    await this[GET_TRAINING]({ eventId, trainingId: idTraining });
    this.trainingName = this.trainingData?.name ?? '';
    this.breadcrumbs.at(1).text = this.trainingName;
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/core/variables';
@import '~@/styles/core/mixins';

@include admin-layout();

#partners {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.partners {
  &__title {
    margin-bottom: 10px;
  }
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
