<template>
  <div class="dialog-report">
    <v-card>
      <v-card-title class="ml-5 font-weight-bold">
        {{
          `${$t(`admin.training.trainingList.popup.title.reportOf`)} ${
            currentItem.profile.firstName
          } ${currentItem.profile.lastName}`
        }}
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center justify-space-between black--text">
          <img :src="currentItem.profile.avatar" class="mr-2" height="100px" />
          <div>
            <p class="font-weight-bold text-center">
              {{ $t('admin.training.trainingList.table.headers.progress') }}
            </p>
            <div class="my-2 pt-1 text-center">
              {{ Math.round((currentItem.totalLessonCompleted / totalLesson) * 100 || 0) }}%
            </div>
          </div>
          <div>
            <p class="font-weight-bold mt-3 text-center">
              {{ $t('admin.training.trainingList.table.headers.finalNote') }}
            </p>
            <v-text-field
              v-model="currentItem.score"
              class="pt-0 text-center"
              placeholder="-"
              required
              style="width: 6rem"
            />
          </div>
        </div>
        <v-card-actions class="d-flex mt-8">
          <v-btn
            color="red"
            data-test-id="cancel"
            outlined
            width="50%"
            @click="$emit('close-dialog')"
          >
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn color="primary" data-test-id="add" width="50%" @click="saveData">
            {{ $t('globals.save') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'DialogReport',
  components: {},
  props: {
    currentItem: {
      type: Object,
      required: true,
    },
    totalLesson: {
      type: Number,
      required: true,
    },
  },
  methods: {
    saveData() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../../../../styles/core/variables';
@import '../../../../../../../../styles/core/mixins';

@include admin-layout;

.training-list-table {
  .clickable {
    cursor: pointer;
  }

  .container-img {
    display: flex;

    &__img {
      width: 150px;
      height: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  @include breakpoint(medium) {
    .container-img {
      width: 100px;
      height: 57px;
      border-radius: 10px 0 0 10px;
      border-right: 1px solid var(--v-gray-base);

      &__img {
        border-radius: 10px 0 0 10px;
      }
    }
  }
}

::v-deep .v-text-field__slot {
  input {
    text-align: center;
  }
}
</style>
